// Name:            Slider
// Description:     Defines styles for a horizontal slider
//
// Component:       `uk-slider`
//
// Sub-objects:     `uk-slider-container`
//
// Modifier:        `uk-slider-fullscreen`
//
// States:          `uk-drag`
//
// Markup:
//
// <!-- uk-slider -->
// <div data-uk-slider>
//     <div class="uk-slider-container">
//         <ul class="uk-slider">
//             <li></li>
//             <li></li>
//         </ul>
//     </div>
// </div>
//
// ========================================================================


/* ========================================================================
   Component: Slider
 ========================================================================== */

/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Deactivate browser history navigation in IE11
 */

.uk-slider {
    /* 1 */
    position: relative;
    /* 2 */
    z-index: 0;
    /* 3 */
    touch-action: pan-y;
}

/*
 * 1. Reset list style without interfering with grid
 */

.uk-slider:not(.uk-grid) {
    /* 1 */
    margin: 0;
    padding: 0;
    list-style: none;
}

/*
 * Sub-object item
 * 1. Position items above each other
 */

.uk-slider > * {
    /* 1 */
    position: absolute;
    top: 0;
    left: 0;
}

/*
 * Clip child elements
 */

.uk-slider-container { overflow: hidden; }

/*
 * Dragged
 */

.uk-slider:not(.uk-drag) {
    -webkit-transition: -webkit-transform 200ms linear;
    transition: transform 200ms linear;
}

/*
 * 1. Makes text unselectable
 */

.uk-slider.uk-drag {
    cursor: col-resize;
    /* 1 */
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*
 * 1. Prevents images and links from being dragged (default browser behavior)
 * 2. Disables the default callout shown when you touch and hold a touch target
 * Currently only works in Webkit
 */

.uk-slider a,
.uk-slider img {
    /* 1 */
    -webkit-user-drag: none;
    user-drag: none;
    /* 2 */
    -webkit-touch-callout: none;
}

/*
 * 1. Prevents images and links from being dragged in Firefox
 */

.uk-slider img { pointer-events: none; }


/* Modifier: `uk-slider-fullscreen`
 ========================================================================== */

.uk-slider-fullscreen,
.uk-slider-fullscreen > li { height: 100vh; }


// Hooks
// ========================================================================

@include hook-slider-misc();

// @mixin hook-slider-misc(){}