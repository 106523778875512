// Name:            Thumbnav
// Description:     Defines styles for a thumbnail navigation
//
// Component:       `uk-thumbnav`
//
// States:          `uk-active`
//
// Markup:
//
// <!-- uk-thumbnav -->
// <ul class="uk-thumbnav">
//     <li class="uk-active"><a href=""></a></li>
//     <li><a href=""></a></li>
// </ul>
//
// ========================================================================


// Variables
// ========================================================================

$thumbnav-margin-horizontal:                     10px !default;
$thumbnav-margin-vertical:                       $thumbnav-margin-horizontal !default;

$thumbnav-background:                            #fff !default;

$thumbnav-opacity:                               0.7 !default;
$thumbnav-hover-opacity:                         1 !default;
$thumbnav-active-opacity:                        1 !default;


/* ========================================================================
   Component: Thumbnav
 ========================================================================== */

/*
 * 1. Gutter
 * 2. Remove default list style
 */

.uk-thumbnav {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    /* 1 */
    margin-left: -$thumbnav-margin-horizontal;
    margin-top: -$thumbnav-margin-vertical;
    /* 2 */
    padding: 0;
    list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `uk-width-*` classes can be applied
 */

.uk-thumbnav > * {
    /* 1 */
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    /* 2 */
    padding-left: $thumbnav-margin-horizontal;
    margin-top: $thumbnav-margin-vertical;
}

/*
 * DEPRECATED IE9 Support
 */

.uk-thumbnav:before,
.uk-thumbnav:after {
    content: "";
    display: block;
    overflow: hidden;
}

.uk-thumbnav:after { clear: both; }

.uk-thumbnav > * { float: left; }


/* Items
 ========================================================================== */

.uk-thumbnav > * > * {
    display: block;
    background: $thumbnav-background;
    @include hook-thumbnav();
}

.uk-thumbnav > * > * > img {
    opacity: $thumbnav-opacity;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

/*
 * Hover
 */

.uk-thumbnav > * > :hover > img,
.uk-thumbnav > * > :focus > img { opacity: $thumbnav-hover-opacity; }

/*
 * Active
 */

.uk-thumbnav > .uk-active > * > img { opacity: $thumbnav-active-opacity; }


// Hooks
// ========================================================================

@include hook-thumbnav-misc();

// @mixin hook-thumbnav(){}
// @mixin hook-thumbnav-misc(){}