$primary-color: #ff8359; // rgba(255, 131, 89, 1)
$primary-color-dark: darken($primary-color, 5%);
$primary-color-light: lighten($primary-color, 5%);
$accent-color: #3eb27a;
$accent-color-dark: darken($accent-color, 5%);
$accent-color-light: lighten($accent-color, 5%);

@import "uikit/uikit-mixins.scss";
@import "uikit/uikit.scss";
@import "uikit/components/autocomplete.scss";
@import "uikit/components/form-select.scss";
@import "uikit/components/datepicker.scss";
@import "uikit/components/dotnav.scss";
@import "uikit/components/slidenav.scss";
@import "uikit/components/slideshow.scss";
@import "uikit/components/slider.scss";
@import "uikit/components/tooltip.scss";


html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #484848;
  font-family: "Hiragino Sans GB","华文细黑","STHeiti","微软雅黑","Microsoft YaHei",SimHei,"Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
}

// general kwibon styles
.kb-color-primary {
    color: $primary-color;
}

.kb-color-accent {
    color: $accent-color;
}

.kb-color-required {
    color: red;
}

.kb-button-primary {
    background: $primary-color;
    color: white;

    &:hover {
        background: $primary-color-light;
        color:white;
    }
}

.kb-button-accent {
    background: $accent-color;
    color: white;

    &:hover {
        background: $accent-color-light;
        color:white;
    }
}

.kb-button-submit {
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    padding: 10px 25px;
    background: $primary-color;
    color: white;
    font-size: 20px;

    &:hover {
        background: $primary-color-light;
        color:white;
    }
}

.kb-divider-header {
    clear: both;
    text-align: center;
    border: 0;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 0;

    &::before, &::after {
        content: "";
        height: 1px;
        width: 50%;
        background-color: rgba(0, 0, 0, 0.28);
        margin: 0;
        display: inline-block;
        vertical-align: middle;
    }

    &::before {
        margin-left: -100%;
        margin-right: 8px;
    }

    &::after {
        margin-left: 8px;
        margin-right: -100%;
    }
}

.uk-overlay {
    display: block;

    img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}



.kb-heading {
  font-size: 36px;
  padding: 12px 8px;
  text-align: center;
  margin: 24px;
}

.kb-heading::before {
  content: none;
}

.kb-heading::after {
  content: attr(data-subtitle);
  text-transform: uppercase;
  display: block;
  font-size: 18px;
  margin-top: 18px;
  color: #808080;
}

footer {
    background-color: #fff;
    padding: 1em 1em;

    * {
        color: #484848 !important;
    }

    a {
        font-size: 12px;
    }

    .contacts {
        font-size: 11px;
        margin-top: 1.5em;

        .phone {
            color: $primary-color-dark !important;
        }
    }

    .copyright {
        color: rgba(255, 255, 255, 0.75);
        font-size: 11px;
        height: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            border-left: solid 1px rgba(0, 0, 0, 0.4);
            display: inline-block;
            list-style: none;
            margin-left: 0.8em;
            padding-left: 0.8em;

            &:first-child {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }

            a {
                color: inherit;
                border: none;
            }
        }
    }
}

.hs-container {
  margin-left:0;
  margin-right:0;
  overflow-y:hidden;

  & > div {
    padding: 0;
    overflow-x:auto;
    overflow-y:hidden;
    white-space:nowrap;
    margin-bottom:-30px;
  }
}

[data-aspect-ratio] {
    display: block;
    max-width: 100%;
    position: relative;

    &:before {
        content: '';
        display: block;
    }

    > * {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
[data-aspect-ratio="4:1"]:before {
  padding-top: 25%;
}
[data-aspect-ratio="3:1"]:before {
    padding-top: 33.33%;
}
[data-aspect-ratio="7:3"]:before {
  padding-top: 42.85%;
}
[data-aspect-ratio="2:1"]:before {
    padding-top: 50%;
}
[data-aspect-ratio="16:9"]:before {
    padding-top: 56.25%;
}
[data-aspect-ratio="3:2"]:before {
    padding-top: 66.66%;
}
[data-aspect-ratio="4:3"]:before {
    padding-top: 75%;
}
[data-aspect-ratio="1:1"]:before {
    padding-top: 100%;
}
[data-aspect-ratio="3:4"]:before {
    padding-top: 133.33%;
}
[data-aspect-ratio="2:3"]:before {
    padding-top: 150%;
}
[data-aspect-ratio="9:16"]:before {
    padding-top: 177.77%;
}
[data-aspect-ratio="1:2"]:before {
    padding-top: 200%;
}
[data-aspect-ratio="1:3"]:before {
    padding-top: 300%;
}

.kb-width-xlarge {
  width: calc(100% / 2 - 20px);
}
.kb-width-large {
  width: calc(100% / 3 - 20px);
  display:inline-block;
  padding:0 6px;
  padding-bottom:30px;
  vertical-align:top;
}
.kb-width-medium {
  width: calc(100% / 4 - 20px);
  display:inline-block;
  padding:0 6px;
  padding-bottom:30px;
  vertical-align:top;
}
.kb-width-small {
  width: calc(100% / 6 - 20px);
}

@media (max-width: 959px) {
  .kb-width-xlarge {
    width: calc(100% / 2 - 30px);
  }
  .kb-width-large {
    width: calc(100% / 2 - 20px);
  }
  .kb-width-medium {
    width: calc(100% / 3 - 20px);
  }
  .kb-width-small {
    width: calc(100% / 4 - 20px);
  }

  .hs-container {
    margin-left:-18px;
    margin-right:-18px;
    overflow-y:hidden;

    & > div {
      padding: 0 12px;
      overflow-x:auto;
      overflow-y:hidden;
      white-space:nowrap;
      margin-bottom:-30px;
    }
  }
}

@media (max-width: 767px) {
  .uk-container {
    padding: 0 8px;
  }
  .kb-width-xlarge {
    width: calc(100% - 30px);
  }
  .kb-width-large {
    width: calc(100% - 20px);
  }
  .kb-width-medium {
    width: calc(100% / 2 - 20px);
  }
  .kb-width-small {
    width: calc(100% / 3 - 20px);
  }

  .hs-container {
    margin-left:-18px;
    margin-right:-18px;
    overflow-y:hidden;

    & > div {
      padding: 0 12px;
      overflow-x:auto;
      overflow-y:hidden;
      white-space:nowrap;
      margin-bottom:-30px;
    }
  }

  .kb-heading {
    font-size: 18px;
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid #dfdfdf;
    margin: 0;
  }

  .kb-heading::after {
    content: none;
  }

  .kb-heading::before {
    content: "";
    display: inline-block;
    position: relative;
    top: calc(-0.1 * 1em);
    vertical-align: middle;
    height: 1em;
    margin-right: 8px;
    border-left: 3px solid #ff8359;
  }

  .kb-divider {
    height: 10px;
    background-color: #f6f9f9;
  }

}
