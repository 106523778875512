// Name:            Autocomplete
// Description:     Provides suggestions while you type into an input field
//
// Component:       `uk-autocomplete`
//
// Modifier:	    (Nav) `uk-nav-autocomplete`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

// Nav modifier
$nav-autocomplete-color:                         #444 !default;
$nav-autocomplete-active-background:             #00a8e6 !default;
$nav-autocomplete-active-color:                  #fff !default;
$nav-autocomplete-header-color:                  #999 !default;
$nav-autocomplete-divider-border:                #ddd !default;
$nav-autocomplete-divider-border-width:          1px !default;


/* ========================================================================
   Component: Autocomplete
 ========================================================================== */

/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Prevent `inline-block` consequences
 * 4. Remove the gap between the container and its child element
 */

.uk-autocomplete {
    /* 1 */
    display: inline-block;
    /* 2 */
    position: relative;
    /* 3 */
    max-width: 100%;
    /* 4 */
    vertical-align: middle;
}

/* Legacy dropdown modifier */
.uk-dropdown-flip {
    left: auto;
    right: 0;
}

/* Nav modifier `uk-nav-autocomplete`
 ========================================================================== */

/*
 * Items
 */

.uk-nav-autocomplete > li > a {
    color: $nav-autocomplete-color;
    @include hook-nav-autocomplete();
}

/*
 * Active
 * 1. Remove default focus style
 */

.uk-nav-autocomplete > li.uk-active > a {
    background: $nav-autocomplete-active-background;
    color: $nav-autocomplete-active-color;
    /* 1 */
    outline: none;
    @include hook-nav-autocomplete-active();
}

/*
 * Sub-object: `uk-nav-header`
 */

.uk-nav-autocomplete .uk-nav-header {
    color: $nav-autocomplete-header-color;
    @include hook-nav-autocomplete-header();
}

/*
 * Sub-object: `uk-nav-divider`
 */

.uk-nav-autocomplete .uk-nav-divider {
    border-top: $nav-autocomplete-divider-border-width solid $nav-autocomplete-divider-border;
    @include hook-nav-autocomplete-divider();
}


// Hooks
// ========================================================================

@include hook-autocomplete-misc();

// @mixin hook-nav-autocomplete(){}
// @mixin hook-nav-autocomplete-active(){}
// @mixin hook-nav-autocomplete-header(){}
// @mixin hook-nav-autocomplete-divider(){}

// @mixin hook-autocomplete-misc(){}
