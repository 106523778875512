// Name:            Thumbnail
// Description:     Defines styles for image thumbnails
//
// Component:       `uk-thumbnail`
//
// Sub-objects:     `uk-thumbnail-caption`
//
// Modifiers:       `uk-thumbnail-mini`
//                  `uk-thumbnail-small`
//                  `uk-thumbnail-medium`
//                  `uk-thumbnail-large`
//                  `uk-thumbnail-expand`
//
// Markup:
//
// <!-- uk-thumbnail -->
// <figure class="uk-thumbnail">
//     <img src="" alt="">
//     <figcaption class="uk-thumbnail-caption"></figcaption>
// </figure>
//
// ========================================================================


// Variables
// ========================================================================

$thumbnail-padding:                              4px !default;
$thumbnail-border:                               #ddd !default;
$thumbnail-border-width:                         1px !default;
$thumbnail-background:                           #fff !default;
$thumbnail-hover-border:                         #aaa !default;
$thumbnail-hover-background:                     #fff !default;

$thumbnail-caption-padding:                      $thumbnail-padding !default;
$thumbnail-caption-color:                        #444 !default;

$thumbnail-mini-width:                           150px !default;
$thumbnail-small-width:                          200px !default;
$thumbnail-medium-width:                         300px !default;
$thumbnail-large-width:                          400px !default;


/* ========================================================================
   Component: Thumbnail
 ========================================================================== */

/*
 * 1. Container width fits its content
 * 2. Responsive behavior
 * 3. Corrects `max-width` behavior sed
 * 4. Required for `figure` element
 * 5. Style
 */

.uk-thumbnail {
    /* 1 */
    display: inline-block;
    /* 2 */
    max-width: 100%;
    /* 3 */
    box-sizing: border-box;
    /* 3 */
    margin: 0;
    /* 4 */
    padding: $thumbnail-padding;
    border: $thumbnail-border-width solid $thumbnail-border;
    background: $thumbnail-background;
    @include hook-thumbnail();
}

/*
 * Hover state for `a` elements
 * 1. Apply hover style also to focus state
 * 2. Needed for caption
 * 3. Remove default focus style
 */

a.uk-thumbnail:hover,
a.uk-thumbnail:focus { // 1
    border-color: $thumbnail-hover-border;
    background-color: $thumbnail-hover-background;
    /* 2 */
    text-decoration: none;
    /* 3 */
    outline: none;
    @include hook-thumbnail-hover();
}


/* Caption
 ========================================================================== */

.uk-thumbnail-caption {
    padding-top: $thumbnail-caption-padding;
    text-align: center;
    color: $thumbnail-caption-color;
    @include hook-thumbnail-caption();
}


/* Sizes
 ========================================================================== */

.uk-thumbnail-mini { width: $thumbnail-mini-width; }
.uk-thumbnail-small { width: $thumbnail-small-width; }
.uk-thumbnail-medium { width: $thumbnail-medium-width; }
.uk-thumbnail-large { width: $thumbnail-large-width; }

.uk-thumbnail-expand,
.uk-thumbnail-expand > img { width: 100%; }


// Hooks
// ========================================================================

@include hook-thumbnail-misc();

// @mixin hook-thumbnail(){}
// @mixin hook-thumbnail-hover(){}
// @mixin hook-thumbnail-caption(){}
// @mixin hook-thumbnail-misc(){}